import { deleteCookiesByPrefixes } from './library/shared-ui/components/CookiePreferenceBanner/utils';

export async function initializeGTM(gtmID) {
  if (!gtmID) {
    console.error('GTM ID is not set');
    return;
  }

  // Inject GTM script into the <head>
  try {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l !== 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtmID);

    // Inject GTM noscript iframe into the <body>
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmID}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    noscript.appendChild(iframe);

    // Append to <body>
    if (document.body) {
      document.body.appendChild(noscript);
    } else {
      console.error('document.body is not available');
    }
  } catch (e) {
    console.error(e);
  }
}

export function removeGTM() {
  // Remove GTM script
  const gtmScript = document.querySelector(
    'script[src*="googletagmanager.com/gtm.js"]'
  );
  if (gtmScript) {
    gtmScript.parentNode.removeChild(gtmScript);
  }

  // Remove GTM iframe
  const gtmIframe = document.querySelector(
    'iframe[src*="googletagmanager.com/ns.html"]'
  );
  if (gtmIframe) {
    gtmIframe.parentNode.removeChild(gtmIframe);
  }

  deleteCookiesByPrefixes(['_ga', '_gcl']);
}
