import styled from 'styled-components';

export const CookieConsentContent = styled.div`
  .cookie-consent-content {
    margin: 8px 4px 8px 8px !important;

    @media (max-width: 480px) {
      font-size: 13px;
    }
  }

  .CookieConsent {
    margin: 20px;
    width: 288px !important;
  }
`;

export const CookieBanner = styled.div`
  position: fixed;
  bottom: 20px;
  transform: translateX(0%);
  z-index: 1;
  max-width: 500px;
  width: 90%;
  right: 1%;

  /* Media query for mobile */
  @media (max-width: 768px) {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
`;
