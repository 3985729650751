import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Card, IconButton, Typography } from '@sezzle/sezzle-mui';
import CloseIcon from '@mui/icons-material/Close';

import { getGeoIpDetails } from '../../../../packages/website/src/api/api';
import {
  getCookiePreference,
  populateCookiePreference,
  setCookiePreference,
} from '../CookiePreferenceBanner/utils';
import {
  CookieBanner,
  CookieConsentContent,
} from '../CookiePreferenceBanner/styled';
import {
  CookieConsentProps,
  CountryCode,
} from '../CookiePreferenceBanner/types';
import CookieConsent from 'react-cookie-consent';
import Text from '../../../shared-ui/components/Text';
import sezzleTheme from '../../../../packages/website/src/templates/Page/themes';

const CookiePreferenceBanner = ({
  oldConsentText,
  newConsentText,
  accept,
  decline,
}: CookieConsentProps) => {
  const [displayBanner, setDisplayBanner] = useState<boolean>(false);
  const defaultAllowedRef = useRef<boolean>(false);
  const [countryCode, setCountryCode] = useState<string>(CountryCode.US);

  const { style, buttonStyle } = useMemo(
    () => ({
      style: {
        background: sezzleTheme.backgroundColor,
        color: '#303030',
        fontFamily: sezzleTheme.buttonFont,
        paddingLeft: '8px',
        paddingTop: '2px',
        paddingBottom: '8px',
        borderRadius: '12px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 4px 16px',
        zIndex: 9999999,
      },
      buttonStyle: {
        color: '#303030',
        background: sezzleTheme.backgroundColor,
        marginLeft: '0px',
        marginRight: '8px',
      },
    }),
    []
  );

  const handleCloseBanner = () => {
    setDisplayBanner(false);
  };

  useEffect(() => {
    const localCookiePreference = getCookiePreference();
    if (!localCookiePreference.acted) {
      setDisplayBanner(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const { status, data: geoIP } = await getGeoIpDetails();
      if (status === 200) {
        setCountryCode(geoIP.country_iso_code.toLowerCase());
        const { allowedAll } = populateCookiePreference(geoIP);
        defaultAllowedRef.current = allowedAll;
      }
    })();
  }, []);

  const handleAccepted = () => {
    setCookiePreference({
      acted: true,
      allowedAll: true,
    });
    handleCloseBanner();
  };
  const handleDeclined = () => {
    setCookiePreference({
      acted: true,
      allowedAll: false,
    });
    handleCloseBanner();
  };
  const handleCloseIcon = (defaultAllowedAll: boolean) => {
    setCookiePreference({
      acted: true,
      allowedAll: defaultAllowedAll,
    });
    handleCloseBanner();
  };

  if (countryCode === CountryCode.US) {
    // Show non-actionable CookieConsent to the US users for now
    return (
      <CookieConsentContent>
        <CookieConsent
          location="bottom"
          cookieName="cookieConsent"
          style={style}
          contentClasses="cookie-consent-content"
          buttonStyle={buttonStyle}
          contentStyle={{
            flex: 10, // large enough to push the x button to the right
          }}
          buttonText="x"
        >
          <Text {...oldConsentText} />
        </CookieConsent>
      </CookieConsentContent>
    );
  }

  return (
    <>
      {displayBanner && (
        <CookieBanner>
          <Card
            sx={{
              borderRadius: 2,
              boxShadow: 2,
              maxWidth: '35rem',
              textAlign: 'center',
              padding: '1rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: '0.5rem 0rem 0.5rem 0.5rem',
              }}
            >
              <Typography
                align="left"
                variant="caption2"
              >
                <Text {...newConsentText} />
              </Typography>
              <IconButton
                data-testid="close-icon"
                onClick={() => handleCloseIcon(defaultAllowedRef.current)}
                sx={{
                  padding: '0rem 0rem 3rem 0rem',
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              <Button
                onClick={handleAccepted}
                sx={{
                  maxHeight: '2.5rem',
                }}
              >
                {accept}
              </Button>
              <Button
                variant="Secondary"
                onClick={handleDeclined}
                sx={{
                  maxHeight: '2.5rem',
                }}
              >
                {decline}
              </Button>
            </Box>
          </Card>
        </CookieBanner>
      )}
    </>
  );
};

export default CookiePreferenceBanner;
