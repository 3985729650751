import StorageManager from '@sezzle/storage-manager';

import {
  COOKIE_PREFERENCE,
  CookiePreferenceConcealed,
  CookiePreferenceData,
} from '../CookiePreferenceBanner/types';
import {
  GeoIPDetails,
  getGeoIpDetails,
} from '../../../../packages/website/src/api/api';

let localStore: StorageManager | null = null;

const getLocalStore = (): StorageManager | null => {
  if (!localStore && typeof window !== 'undefined') {
    localStore = new StorageManager(false, 'local', true);
  }
  return localStore;
};

const defaultCookiePreference: CookiePreferenceData = {
  acted: false,
  allowedAll: false,
};

export const getCookiePreference = (): CookiePreferenceData => {
  try {
    const store = getLocalStore();
    if (!store) return defaultCookiePreference;

    const cookiePreferenceStr = store.get(COOKIE_PREFERENCE);
    if (cookiePreferenceStr) {
      const { a, x } = JSON.parse(
        cookiePreferenceStr
      ) as CookiePreferenceConcealed;
      return { acted: a === 1, allowedAll: x === 1 };
    }
  } catch (error) {
    console.error('Failed to parse cookie preference:', error);
  }
  return defaultCookiePreference;
};

export const setCookiePreference = (preference: CookiePreferenceData): void => {
  const store = getLocalStore();
  if (!store) return;

  const concealedData: CookiePreferenceConcealed = {
    a: preference.acted ? 1 : 0,
    x: preference.allowedAll ? 1 : 0,
  };

  const oneYearInFuture = new Date();
  oneYearInFuture.setFullYear(oneYearInFuture.getFullYear() + 1);

  store.set(COOKIE_PREFERENCE, JSON.stringify(concealedData), oneYearInFuture);
};

export const getDefaultPreferenceByGeoIP = (
  geoIP: GeoIPDetails
): CookiePreferenceData => ({
  acted: false,
  allowedAll: !(
    geoIP.country_iso_code === 'CA' && geoIP.region_iso_code === 'QC'
  ),
});

export const populateCookiePreference = (
  geoIP: GeoIPDetails
): CookiePreferenceData => {
  const existingPreference = getCookiePreference();
  if (existingPreference.acted) return existingPreference;

  const defaultPreference = getDefaultPreferenceByGeoIP(geoIP);
  setCookiePreference(defaultPreference);
  return defaultPreference;
};

export const deleteCookiesByPrefixes = (prefixes: string[]) => {
  const store = getLocalStore();
  if (!store) return;

  store.unsetByPrefixes(prefixes);
};

export const getCookiePreferences = async (): Promise<CookiePreferenceData> => {
  const { status, data } = await getGeoIpDetails();
  if (status === 200) {
    return populateCookiePreference(data);
  }
  return {
    acted: false,
    allowedAll: false,
  };
};
